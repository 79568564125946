<template>
    <div class="account">
        <div class="main">
            <el-form
                hide-required-asterisk
                :label-width="isChinese ? '120px' : '180px'"
                :rules="rules"
                size="small"
                :model="form"
                style="width: 480px"
                ref="form"
            >
                <el-form-item :label="$t('shou-ji-hao')" prop="phone">
                    <Phone v-model="form.phone"></Phone>
                </el-form-item>
                <el-form-item class="code" :label="$t('yan-zheng-ma')" prop="code">
                    <div class="codeInput input2">
                        <el-input v-model="form.code" :placeholder="$t('shu-ru-yan-zheng-ma')" maxlength="6">
                        </el-input>
                        <el-button type="warning" plain @click="sendMsg" :disabled="isSend">
                            {{ isSend ? `${$t('yi-fa-song')}(${senNum}S)` : $t('huo-qu-yan-zheng-ma') }}</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item :label="$t('xin-mi-ma')" prop="newPassword">
                    <el-input
                        v-model="form.newPassword"
                        type="password"
                        :placeholder="
                            isChinese
                                ? $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he')
                                : $t('qing-shu-ru')
                        "
                        maxlength="16"
                    ></el-input>
                    <div class="en-tips" v-if="!isChinese">
                        {{ $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he') }}
                    </div>
                </el-form-item>
                <el-form-item :label="$t('que-ren-mi-ma')" prop="newPassword2">
                    <el-input
                        v-model="form.newPassword2"
                        type="password"
                        :placeholder="
                            isChinese
                                ? $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he')
                                : $t('qing-shu-ru')
                        "
                        maxlength="16"
                    ></el-input>

                    <div class="en-tips" v-if="!isChinese">
                        {{ $t('816-ge-zi-fu-jian-yi-shi-yong-liang-zhong-huo-liang-zhong-yi-shang-zi-fu-zu-he') }}
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="submit" type="warning" @click="onSubmit" :loading="loading">{{
                        $t('que-ren')
                    }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import formPage from '../../mixins/formPage';
import Phone from '../../components/PhoneInput';
import { backPhone, regular } from '../../utils/Sms';
import { mapState } from 'vuex';
export default {
    name: 'manage',
    mixins: [formPage],
    data() {
        return {
            form: {},
            rules: {
                phone: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                callback();
                            } else {
                                callback(new Error(this.$t('qing-shu-ru-shou-ji-hao-ma')));
                            }
                        }
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (regular.test(value)) {
                                this.$http
                                    .post('/auth/isPhoneReg', {
                                        phone: backPhone(value)
                                    })
                                    .then(res => {
                                        if (res) {
                                            callback();
                                        } else {
                                            callback(new Error(this.$t('gai-shou-ji-hao-wei-zhu-ce')));
                                        }
                                    });
                            } else {
                                callback(new Error(this.$t('shou-ji-hao-ge-shi-cuo-wu')));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                newPassword: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            console.log(value);
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('xin-mi-ma-bu-neng-wei-kong'));
                            }
                        },
                        trigger: 'blur'
                    }
                ],
                newPassword2: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            console.log(value);
                            if (value) {
                                callback();
                            } else {
                                callback(this.$t('que-ren-mi-ma-bu-neng-wei-kong'));
                            }
                        },
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value === this.form.newPassword) {
                                callback();
                            } else {
                                callback(new Error(this.$t('liang-ci-mi-ma-bu-yi-zhi')));
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(['userInfo', 'showLogin'])
    },
    created() {
        this.$parent.$refs.login.loginVisible = false;
    },
    beforeDestroy() {
        if (this.showLogin) {
            this.$parent.$refs.login.loginVisible = true;
        }
    },
    methods: {
        submit() {
            this.$http
                .post('/auth/forgetPassword', {
                    phone: backPhone(this.form.phone),
                    code: this.form.code,
                    password: this.form.newPassword,
                    password2: this.form.newPassword2
                })
                .then(res => {
                    this.$store.dispatch('getUserInfo');
                    this.$message.success('修改成功！');
                    this.$router.go(-1);
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    },
    components: { Phone }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.input2 {
    width: 360px;
}
.account {
    background-color: #fff;
    min-height: 20px;
    // min-height: calc(max(100vh, 1000px) - 90px);

    @include max-height(90px, $max: 1200px);
    .el-form {
        margin: 100px auto;
        .codeInput {
            display: flex;
            align-items: center;
            .el-button {
                padding: 11px 16px;
                // min-width: 142px;
                box-sizing: border-box;
            }
        }

        .submit {
            min-width: 222px;
            margin-top: 60px;
            height: 40px;
        }

        .el-form-item {
            margin-bottom: 20px;
        }
    }
}
</style>
